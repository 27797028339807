import React from 'react';
import { graphql } from 'gatsby';
import BlogPage from 'components/pages/BlogPage';

const seo = {
  title: 'Q°emotion - The blog of emotions and customer experience',
  keywords: [
    'Blog',
    'Emotional analysis',
    'AI',
    'Customer feedback',
    'Q°emotion tools',
    'posts',
    'client experience',
    'emotions',
  ],
  description:
    'In this section you will find all of the Q°emotion blog posts that deal with customer experience',
};

export default props => (
  <BlogPage {...props} seo={seo} activeCategory="customerRelation" />
);

export const pageQuery = graphql`
  query($limit: Int!, $status: GCMS_Stage!) {
    dataBlockIntroTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "blog" }
        blockType: { eq: "introBlock" }
        lang: { eq: "es" }
      }
    ) {
      rawMarkdownBody
      frontmatter {
        title
      }
    }
    dataArticlesList: gcms {
      posts(
        where: { lang: ES, category: CustomerRelation }
        first: $limit
        orderBy: createdAt_DESC
        stage: $status
      ) {
        content {
          html
          text
        }
        createdAt
        id
        lang
        metaDescription
        metaKeywords
        thumbnail {
          url
        }
        title
        slug
      }
    }
    dataBlockLinkBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "blog" }
          blockType: { eq: "linkBlock" }
          lang: { eq: "es" }
        }
      }
      sort: { fields: [frontmatter___idBlock] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          subTitle
          color
          link
          buttonText
        }
      }
    }
  }
`;
